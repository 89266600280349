<template>
  <div
    class="embed-models-wrp"
    :class="{ loading: isLoading, custom__font: fontFamily }"
    :style="wrpStyle"
  >
    <div class="inner__block" ref="inner">
      <div class="count-text" v-if="searchable && totalItems">
        В каталоге: {{totalItems}} {{declOfNum(totalItems, ['модель', 'модели', 'моделей'])}}
      </div>
      <div class="flex search__block" v-if="searchable || makeCategoryFilter">
        <div class="flex-auto select__wrp" v-if="makeCategoryFilter">
          <bim-select
            v-if="categoryFilter"
            class="select__m"
            placeholder="Категория"
            :options="categoryOptions"
            width="100%"
            valueName="id"
            labelName="name"
            v-model="form.code_category"
          />

          <bim-select
            v-if="!categoryFilter"
            class="select__m"
            placeholder="Раздел"
            :options="sectionsOptions"
            width="100%"
            valueName="id"
            labelName="text"
            v-model="form.sections"
          />
        </div>
        <div class="flex-item">
          <search-block
            v-if="searchable"
            v-model="filterName"
            :searchBorderColor="searchBorderColor"
            @search="fetchModels(1)"
          />
        </div>
      </div>


      <div v-if="notFoundModels">
        <div class="not-found-title">Подходящих моделей не найдено</div>
        <div class="not-found-desc">Попробуйте изменить поиск</div>
      </div>

      <div
        class="flex__cards"
        :class="{
          big__wide: type === 'big-wide',
        }"
        :style="{
          gridTemplateColumns: `repeat(${countInRow}, 1fr)`
        }"
      >
        <bim-product-card
          v-for="model in models"
          :key="model.id"
          v-bind="formModel(model)"
          :type="type"
          target="_blank"
          width="100%"
          :borderHoverColor="primaryColor"
          @btnClicked="onBtnClicked(model)"
        />
      </div>

      <bim-pagination
        class="models__pagination"
        :activePage="activePage"
        :totalPages="totalPages"
        @changePage="fetchModels"
        :primaryColor="primaryColor"
      />

      <div class="footer" v-if="models.length">
        <div class="hint-text">Работает на технологиях</div>
        <a class="link-bim-logo" :href="host"><img class="bim-logo" :src="bimlibLogo" /></a>
      </div>
    </div>
  </div>
</template>

<script>
  import bimSelect from '@bimlib/components/bimSelect'
  import bimPagination from '@bimlib/components/bimPagination'
  import bimProductCard from '@bimlib/components/bimProductCard'
  import FormModelCardDataMixin from '@/mixins/models/form-model-card-data'
  import SearchBlock from './search-block'
  import declOfNum from '@/utils/declOfNum'

  function throttle(time, func, ...args) {
    let isCalled = false;

    return function() {
      if (isCalled) return;

      setTimeout(async ( ) => {
        await func(...args)
        isCalled = false
      }, time)

      isCalled = true;
    }
  }

  export default {
    name: 'Models',
    mixins: [FormModelCardDataMixin],
    props: {
      host: {
        type: String,
        // default: 'https://dev.bimlib.ru'
        // default: 'http://ldev.bimlib.ru:81'
        default: 'https://bimlib.pro'
      },
      queryParams: { // параметры запроса за данными base64
        type: String,
        default: 'c29ydD1wb3B1bGFy' // sort=popular
      },
      type: { // вид отображения (плитки или списком)
        type: String,
        default: 'card',
        validator (value) {
          return ['card', 'big-wide', 'wide'].indexOf(value) !== -1
        }
      },
      primaryColor: { // Основной цвет
        type: String,
        default: '#00afa1'
      },
      searchBorderColor: { // цвет бордера у поиска
        type: String,
        default: '#a2a2a2'
      },
      searchable: { // включить строку поиска
        type: Boolean,
        default: false
      },
      fontFamily: { // шрифт
        type: String,
        default: ''
      },
      css: { // кастомный css
        type: String,
        default: ''
      },
      utm: { // utm на открытие ссылок
        type: String,
        default: 'dXRtX3NvdXJjZT1lbWJlZCZ1dG1fbWVkaXVtPW9yZ2FuaWMmdXRtX2NhbXBhaWduPWJpbWxpYjkyMzE2MDY3ZmZhYThlODEyNDNiMzJjNjcxM2U3OGYxJnV0bV9jb250ZW50PXd3dy5yb2xzLWlzb21hcmtldC5ydQ=='
      },
      makeCategoryFilter: { // дать возможность фильтровать по категориям
        type: Boolean,
        default: false
      },
      categoryFilter: { // фильтр по категории (false - по разделу, true - по категории)
        type: Boolean,
        default: false
      },
      companyArticle: { // артикул компании
        type: String,
        default: ''
      },
      modelId: { // id конкретной модели
        type: [String, Number],
        default: ''
      }
    },
    components: {
      'bim-pagination': bimPagination,
      'bim-product-card': bimProductCard,
      'search-block': SearchBlock,
      'bim-select': bimSelect
    },
    data () {
      return {
        countInRow: 5, // количество моделей в строке
        isLoading: true, // пока идет загрузка
        sectionsOptions: [], // список разделов
        categoryOptions: [], // список категорий
        form: {
          code_category: '', // раздел
          sections: '' // категория
        },
        mounted: false,
        wrapperElement: null,
        filterName: '',
        notFoundModels: false,
        activePage: 1,
        totalPages: 1,
        totalItems: 0,
        models: [], // массив моделей
        bimlibLogo: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='86' height='14' viewBox='0 0 86 14'%3E%3Cpath fill='%2304afa1' fill-rule='evenodd' d='M42.957-.004v13.975h-3.652V9.619l-3.553 4.401-3.622-4.498v4.449h-3.584V-.004l7.206 8.649 7.205-8.649zm25.263 0v13.975h-3.642V-.004h3.642zm-46.797 0v13.975h-3.638V-.004h3.638zm32.584 0V10.45h3.449v3.522h-6.964V-.004h3.515zm32.013 0v13.975h-7.318c-1.467 0-4.62-.697-4.62-4.014 0-3.318 2.333-3.664 2.333-3.664s-1.126-.623-1.126-2.65c0-2.029 1.488-3.663 4.461-3.663 1.982 0 4.072.005 6.27.016zm-79.816 0c2.973 0 4.464 1.57 4.464 3.598 0 1.866-.954 2.542-1.106 2.638l-.02.013s2.333.346 2.333 3.664c0 3.317-3.153 4.013-4.62 4.013H-.018L-.02.012c2.167-.01 4.242-.016 6.224-.016zM82.27 8.64h-2.91c-.714 0-1.485.284-1.485 1.26 0 .977.771 1.374 1.468 1.374h2.927V8.64zM6.596 8.592h-2.91v2.634h2.926c.697 0 1.468-.397 1.468-1.374 0-.976-.77-1.26-1.484-1.26zM82.252 3.04h-2.233c-.576 0-.894.438-.894.936 0 .498.318.927.831.927h2.287l.009-1.863zM5.938 3.007H3.705l.008 1.864H6c.514 0 .832-.43.832-.928 0-.498-.318-.936-.894-.936z'/%3E%3C/svg%3E%0A"
      }
    },
    async mounted () {
      this.wrapperElement = this.$refs.inner;

      // Чтобы не лагало
      const throttledResize = throttle(1000, this.fetchModels, this.activePage)
      window.addEventListener('resize', throttledResize)

      this.setCustomCss()

      // получим список разделов
      if (this.makeCategoryFilter) {
        if (this.categoryFilter) {
          this.companyArticle ?
            await this.fetchCategoriesByArticle() :
            await this.fetchCategoriesByManufacturer()
        } else {
          this.companyArticle ?
            await this.fetchSectionsByArticle() :
            await this.fetchSectionsByManufacturer()
        }
      }

      // заполним по дефолту
      const params = this.getQueryParams()
      if (params.get('code_category')) {
        this.form.code_category = params.get('code_category')
      }
      if (params.get('sections')) {
        this.form.sections = +params.get('sections')
      }

      this.fetchModels()
      this.mounted = true
    },
    computed: {
      wrpStyle() {

        if (!this.fontFamily) {
          return false
        }

        return {
          '--fontfamily': `${this.fontFamily},-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,Helvetica Neue,sans-serif`
        }
      },

      utmLink () {
        if (!this.utm) {
          return ''
        }

        let utm = ''

        try {
          utm = atob(this.utm)
        } catch (e) {
          utm = ''
        }

        try {
          const params = new URLSearchParams(utm)
          params.set('utm_content', window.location.host)
          utm = params.toString()
        } catch (e) {
          utm = ''
        }

        return utm
      }
    },
    methods: {
      declOfNum,

      async fetchCategoriesByArticle() {
        const params = {
          m: 'GetList',
          t: 'sections',
          id_company: this.companyArticle
        }

        this.categoryOptions = await axios.get(`${this.host}/`, { params }, { withCredentials: true })
          .then(res => res.data)
          .then(res => res.rows)

        this.categoryOptions.unshift({
          id: '',
          name: 'Все категории'
        })
      },

      async fetchCategoriesByManufacturer() {
        const id = this.getQueryParams().get('manufacturer')
        return axios
          .get(
            `${this.host}/web-api-2/objcategory/company/${id}`,
            null,
            { withCredentials: true }
          )
          .then(res => res.data.data)
          .then(data => {
            this.categoryOptions = [{ id: '', name: 'Все категории' }].concat(
              data.map(({ name, code }) => ({ name, id: code }))
            )
          })
      },

      async fetchSectionsByArticle() {
        const params2 = {
          m: 'Manufacturers',
          a: 'getUserBlockManModelsArticles',
          id: this.companyArticle
        }

        let cats = []

        try {
          cats = await axios.get(`${this.host}/`, { params: params2 }, { withCredentials: true })
            .then(res => res.data)
            .then(res => res.Result)
            .then(res => res.Cats)
        } catch (e) {
          console.error('error get cats')
          cats = []
        }

        const params = {
          limit: 9999,
          m: 'GetSortList',
          t: 'category'
        }

        await axios.get(`${this.host}/`, { params }, { withCredentials: true })
          .then(res => res.data)
          .then(res => res.rows)
          .then(rows => {
            this.sectionsOptions = rows.filter(item => ~cats.indexOf(item.id))

            this.sectionsOptions.unshift({
              id: '',
              text: 'Все разделы'
            })
          })
      },

      async fetchSectionsByManufacturer() {
        const id = this.getQueryParams().get('manufacturer')
        return axios
          .get(
            `${this.host}/web-api-2/getsections/company/${id}`,
            null,
            { withCredentials: true }
          )
          .then(res => res.data.data)
          .then(data => {
            this.sectionsOptions = [{ id: '', text: 'Все разделы' }].concat(
              data.map(({ name, id }) => ({ id, text: name }))
            )
          })
      },

      // кастомный css
      setCustomCss () {

        if (!this.css) {
          return
        }

        let css = ''

        try {
          css = atob(this.css)
        } catch (e) {
          css = ''
        }

        const style = document.createElement('style')
        style.type = 'text/css'
        if (style.styleSheet){
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css))
        }

        document.body.appendChild(style)
      },

      formModel (model) {
        return {
          ...this.formModelData(model, this.type, true, this.host, this.utmLink),
          btnName: 'Скачать'
        }
      },

      getQueryParams() {
        let queryParams = ''

        if (this.queryParams) {
          try {
            queryParams = atob(this.queryParams)
          } catch (e) {
          }
        }

        return  new URLSearchParams(queryParams)
      },

      async fetchModels (activePage = 1) {
        /* запрашиваемые поля модели */
        const listFields = [
          'id', 'name', 'name_short', 'images', 'company_uuid'
        ]

        const width = this.wrapperElement.clientWidth // ширина блока внутреннего
        const cardWidth = 148 // ширина карточки
        const countInRow = Math.round(width / cardWidth) // количество моделей в ряду
        this.countInRow = countInRow

        let rows = 4
        let limit = 7

        // tablet
        if (width > 700) {
          rows = 3
        }

        // pc
        if (width > 1000) {
          rows = 2
        }

        if (this.type === 'card') {
          limit = (rows * countInRow)
        }

        if (this.type === 'wide') {
          this.countInRow = 1
        }

        if (this.type === 'big-wide') {
          listFields.push('description')
          this.countInRow = 1
          limit = 5
        }

        const params = this.getQueryParams()
        params.set('limit', limit)
        params.set('embed', true)
        params.set('page', activePage)
        params.set('offset', (activePage - 1) * limit || 0)

        if (this.filterName) {
          params.set('name', this.filterName)
        }

        // включен ручной фильтр по категориям
        if (this.makeCategoryFilter) {
          if (this.form.code_category) {
            params.set('code_category', this.form.code_category)
          } else {
            params.delete('code_category')
          }

          if (this.form.sections) {
            params.set('sections', this.form.sections)
          } else {
            params.delete('sections')
          }
        }

        // для перехода на v2
        const manufacturer = params.get('manufacturer')
        if (manufacturer && Number.isNaN(+manufacturer)) {
          params.set('company_uuid', manufacturer)
          params.delete('manufacturer')
        }

        // переезжаем на v2 компаний - которые использовали старый id компании
        const manufacturersHash = {
          'danifox.beget.tech': '9d9d6b6e-9ae9-4d76-bbce-4998417d8b0b',
          'abradox.ru': '99298916-bc49-420c-9de7-f1c1dc1a4089',
          'albes.ru': '256b0810-89e0-4a08-b828-abdb79ea6a46',
          'cersanit.ru': 'b0043f3d-7139-4c6a-850e-c3874e1162a8',
          'fireproff.ru': '3d751920-300c-4eb2-80b8-d86ec3861ff0',
          'freestyle-tech.ru': 'b691e3df-75df-45fa-a82b-a8a160eaacb2',
          'hub.saint-gobain.ru': '', // там их много - https://hub.saint-gobain.ru/content/bim-obekty-dostupnye-na-bimlib
          'kirov.exprof.pro': '4c7e59f9-5040-413e-a2c3-8bcf2452c7e9',
          'mercorproof.ru': '2d080b3d-e4cb-422e-9bc4-de8cf7b7aa6a',
          'plazma-t.ru': '3a79d9fa-e0ac-452e-b998-e172015f533b',
          'solidgroup.ru': 'dff15b3f-18cb-42f0-aa04-35594dcd3121',
          'thermex.ru': 'ef0c0a38-620d-4495-8d50-b54df6f561d3',
          'tropik-line.ru': 'c769b805-021b-4c28-b765-c1f42f7d4f18',
          'wolfrus.ru': 'bea3199c-cbde-48b8-befd-ec3ea801b384',
          'www.npopuls.ru': '9d9d6b6e-9ae9-4d76-bbce-4998417d8b0b',
          'www.phototech.ru': '5207bca0-bbdc-48a2-bd41-059b3b28183e',
          'www.proaqua.pro': 'a5d59f20-0165-4a99-9ba8-23bcc0a19598',
          'www.rols-isomarket.ru': 'c39bfd4a-03c7-4cc6-bab0-5620643a427c',
          'www.tcontrol.ru': '8960737e-5cb4-4525-a5fc-c01b79e2fc2b',
          'www.teplovodomer.ru': '710bfa4c-363d-47d6-9bb2-72713b6ca798',
          'xn----jtbpcxkjj.xn--p1ai': '4c05b905-3c7a-48a8-99b5-2e14db757070',
        }

        if (manufacturersHash[window.location.hostname]) {
          params.set('company_uuid', manufacturersHash[window.location.hostname])
        }

        if (this.modelId) {
          params.set('model_id', this.modelId)
        }

        this.isLoading = true
        this.notFoundModels = false

        params.set('list_fields', listFields.join())

        try {
          // const result = await axios.post(`${this.host}/?m=BimModelsList&a=getModelsJson`, params, {withCredentials: true})
          const result = await axios.post(`${this.host}/web-api-2/model/searchmodels/`, params, {withCredentials: true})
            .then(res => res.data)

          this.activePage = activePage

          this.totalPages = result.totalPages
          this.totalItems = result.total

          // Разбиваем массив по строкам
          this.models = result.models

          if (!this.models.length) {
            this.notFoundModels = true
          }
        } catch (e) {
          console.error('fetchModels', e)
        } finally {
          this.isLoading = false
        }
      },

      onBtnClicked (model) {
        const formModel = this.formModel(model)
        window.open(formModel.link)
      }
    },
    watch: {
      form: {
        deep: true,
        handler() {
          if (!this.mounted) {
            return
          }

          this.fetchModels()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import url("https://fonts.googleapis.com/css?family=Open+Sans");

  .embed-models-wrp {
    width: 100%;
    border-radius: 5px;
    border: solid 0.5px #e6e6e6;
    background-color: #fafafa;
    padding: 24px 80px;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    min-height: 300px;
    overflow: hidden;
    font-family: Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,Helvetica Neue,sans-serif;
  }

  .embed-models-wrp.custom__font {
    font-family: var(--fontfamily)
  }

  .inner__block {
    width: 100%;
  }

  .loading:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255, 0.8);
  }

  .footer {
    text-align: center;
    margin-top: 30px;
  }

  .hint-text {
    opacity: 0.5;
    color: #4d4d4d;
    font-size: 9px;
    margin-bottom: 8px;
  }

  .link-bim-logo {
    display: block;
    margin: 0 auto;
    cursor: default;
  }

  .bim-logo {
    display: block;
    margin: 0 auto;
    width: 86px;
    opacity: 0.45;
    filter: grayscale(100);
  }

  .bim-logo:hover {
    cursor: pointer;
    filter: grayscale(0);
    opacity: 1;
  }

  .flex__cards {
    display: grid;
    grid-gap: 8px;

    &.big__wide {
      grid-gap: 32px;
    }
  }



  .models__pagination {
    margin-top: 30px;
  }

  .count-text {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.13;
    margin-bottom: 40px;
    color: #4d4d4d;
  }

  .not-found-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    color: #4d4d4d;
    opacity: 0.9;
  }

  .not-found-desc {
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
    opacity: 0.5;
    color: #4d4d4d;
  }

  .search__block {
    margin-bottom: 24px;
    display: flex;
    flex: 1;
  }

  .flex-auto {
    flex: 0 0 auto;
  }

  .flex-item {
    flex: 1;
  }

  .select__m::v-deep .input__form,
  .select__m::v-deep .arrow__icon__wrp
  {
    height: 46px;
  }

  .select__m::v-deep .input__form {
    border-color: #e6e6e6;
    background-color: white;
  }

  .select__wrp {
    margin-right: 24px;
    width: 270px;
  }

  // tablet
  @media all and (max-width: 800px) {
    .embed-models-wrp {
      padding: 24px;
    }
    .models__pagination,
    .footer {
      margin-top: 24px;
    }
  }

  @media all and (max-width: 600px) {
    .search__block {
      display: block;
    }
    .select__wrp {
      margin-bottom: 20px;
      margin-right: 0;
      width: 100%;
    }
  }

  // iphone5
  @media all and (max-width: 320px) {
    .embed-models-wrp {
      padding: 24px 12px;
    }
  }
</style>
