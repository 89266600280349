<template>
  <div>
    <div class="input__wrp">
      <img class="search__icon" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%234D4D4D' fill-rule='evenodd' d='M7 1.25c3.176 0 5.75 2.574 5.75 5.75 0 1.317-.443 2.531-1.188 3.5l2.968 2.97c.293.293.293.767 0 1.06-.266.267-.683.29-.976.073l-.084-.073-2.97-2.968c-.969.745-2.183 1.188-3.5 1.188-3.176 0-5.75-2.574-5.75-5.75S3.824 1.25 7 1.25zm0 1.5C4.653 2.75 2.75 4.653 2.75 7S4.653 11.25 7 11.25 11.25 9.347 11.25 7 9.347 2.75 7 2.75z' opacity='.5'/%3E%3C/svg%3E%0A" />
      <input
        class="input"
        type="text"
        placeholder="Поиск по моделям"
        v-model="obsValue"
        @keyup.enter="submit"
        @input="onInput"
        :style="style"
      />
      <div class="go__search" @click="submit">
        <img class="go__search__icon" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17'%3E%3Cpath fill='%234D4D4D' fill-rule='evenodd' d='M17.903 8.553l.015.03.029.076c.005.014.01.029.013.044.008.026.015.053.02.08l.006.034c.005.028.009.056.01.085.002.014.003.028.003.043l.001.037-.001.046-.003.04.004-.086c0 .059-.005.116-.015.173l-.007.034c-.006.03-.014.06-.023.09l-.007.023-.006.017c-.04.112-.1.215-.175.305l-.008.008-6 7c-.36.42-.99.468-1.41.109-.387-.332-.458-.895-.184-1.31l.076-.1 4.584-5.35H1c-.552 0-1-.447-1-1 0-.512.386-.935.883-.993L1 7.982h13.825l-4.584-5.35c-.36-.419-.311-1.05.108-1.41.387-.331.955-.315 1.323.019l.087.09 6 7 .008.009c.051.061.096.13.131.202l.005.01z'/%3E%3C/svg%3E%0A" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBlock',
  props: {
    value: String,
    searchBorderColor: String
  },
  data () {
    return {
      obsValue: this.value
    }
  },
  computed: {
    style () {
      return {
        '--borderhover': this.searchBorderColor
      }
    }
  },
  methods: {
    onInput(e) {
      const { value } = e.target
      this.$emit('input', value)
    },
    submit () {
      this.$emit('search')
    }
  }
}
</script>

<style lang="scss" scoped>
  .input__wrp {
    position: relative;
  }

  .input {
    height: 46px;
    border: solid 1px #e6e6e6;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    font-size: 14px;
    font-family: inherit;
  }

  .input:hover {
    border-color: #c7c6c6;
  }

  .input:focus {
    outline: none;
    border-color: var(--borderhover);
  }

  .search__icon {
    position: absolute;
    top: 50%;
    left: 14px;
    margin-top: -8px;
    display: block;
    width: 16px;
    pointer-events: none;
  }

  .go__search {
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 4px;
    width: 38px;
    opacity: 0.4;
  }

  .go__search__icon {
    position: absolute;
    display: block;
    width: 18px;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -9px;
  }

  .go__search:hover {
    opacity: 0.8;
    background-color: #eee;
    cursor: pointer;
  }
</style>
