import { get } from 'lodash'

export default {
  methods: {
    // Структурируем данные модели для карточки из @bimlib/components/bimProductCard
    // model - Object
    // type - ['card', 'wide', 'big-wide']
    // showManufacturer - показывать данные производителя
    formModelData (model, type= 'card', showManufacturer = true, host = '', utm = '') {

      const name = (model) => {
        if (type === 'wide') {
          return model.name
        }

        return model.name_short
      }

      const manufacturerImage = (model) => {
        if (model.user && model.user.role === 'manufacturer' && model.logo.link) {
          return `${host}${model.logo.link}?size=-x56`
        } else if (model.user && model.user.avatarbasic) {
          return `${host}${model.user.avatarbasic}?size=-x56`
        } else if (model.company && model.company.logo) {
          return `${host}/uploads/images/${model.company.logo}?size=-x56`
        } else {
          return `${host}/dist/assets/front_white/img/svg/no-pic-company.svg`
        }
      }

      const imageHost = `${host}/uploads`
      // Изображение модели
      let image = Object.keys(get(model, 'images', {}))
      if (image.length) {
        image = imageHost + get(model, `images.${image[0]}.link_picture`)
      } else if (model.link_picture) {
        image = imageHost + model.link_picture
      } else {
        image = `${host}/dist/assets/front_white/img/svg/no-pic.svg`
      }

      const data = {
        image: image,
        name: name(model),
        link: `${host}/model/${model.name_alias || model.alias_model}/${model.id}/${utm ? `?${utm}` : ''}`
      }

      if (type === 'wide') {
        data.description = model.category
      }

      if (type === 'big-wide') {
        data.description = model.description
      }

      // получаем компанию по старому
      if (showManufacturer && model.user) {
        data.manufacturer = {
          name: model.name_organization,
          image: manufacturerImage(model)
        }

        if (model.user.role === 'manufacturer') {
          data.manufacturer.link = `${host}/manufacturer/${model.alias_organization || 'company'}/${model.id_organization}/`
        }
      }

      // получаем компанию по новому
      if (showManufacturer && model.company) {
        data.manufacturer = {
          name: model.company.name_short,
          image: manufacturerImage(model),
          link: `${host}/manufacturer/${model.company.alias_organization || model.company.name_alias || model.company.alias || 'company'}/${model.company.id}/`
        }
      }

      const { restriction = [] } = model
      data.showLockIcon = (restriction.find(r => r.type_restriction === 'mute') || {}).active || false

      return data
    }
  }
}
